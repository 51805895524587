import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Col, Divider, Input, Modal, Radio, Row,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useOrderContext } from '../context';
import { Company, useCompaniesAllForCustomer } from '../../../../../hooks/api/company';
import { useOrderUpdate } from '../../../../../hooks/api/order';
import { useAuth } from '../../../../../store/auth';
import { AddNewCustomerModalButton } from '../AddNewCustomer';

import styles from './index.module.scss';

interface ChooseCustomerProps {
  handleOpen?: (modalOpen: boolean) => void;
  isModalOpen: boolean;
  showFooter?: boolean;
}

const LIST_MAX_HEIGHT = 'min(calc(80vh - 106px), 600px)';

function ChooseCustomerModal({ handleOpen, showFooter = true, isModalOpen }: ChooseCustomerProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const { user } = useAuth();
  const orderUpdate = useOrderUpdate(id || '', user?.role === 'user');
  const { order, clientOrderData } = useOrderContext();
  const [filteredCustomerData, setFilteredCustomerData] = useState<Company[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Company>();
  const customerList = useCompaniesAllForCustomer();

  const [search, setSearch] = useState('');

  useEffect(() => {
    if ((search.length >= 3 || search === '') && !customerList.loading) {
      const timeoutId = setTimeout(() => customerList.fetch({ search: search || undefined }), 500);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [search]);

  useEffect(() => {
    if (!orderUpdate.error && !orderUpdate.loading && orderUpdate.data) {
      order.fetch();
    }
  }, [orderUpdate.data]);

  /** Filtering data */
  useEffect(() => {
    const newData = customerList.data || [];
    const filterEmail = clientOrderData?.shipper?.email;
    const filterCompany = clientOrderData?.shipper?.company;
    const filterAddress = clientOrderData?.shipper?.address1;

    const filteredData: Company[] = !search
      ? newData?.filter((item) => (
        item.companyName === filterCompany || item.email === filterEmail || item.address1 === filterAddress
      ) && item.status !== 'delete')
      : newData?.filter((item) => item.status !== 'delete');

    if (JSON.stringify(filteredData) !== JSON.stringify(filteredCustomerData)) {
      setFilteredCustomerData(filteredData);
    }
  }, [customerList, clientOrderData, filteredCustomerData, order]);

  const handleSelectCustomer = (customer: Company) => {
    setSelectedCustomer(customer);
  };

  return (
    <Modal
      title="Add customer"
      open={isModalOpen}
      centered
      closable
      maskClosable={false}
      width={952}
      footer={
        showFooter
          ? [
            <Button
              key="cancel"
              onClick={() => {
                handleOpen?.(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="agree"
              type="primary"
              onClick={() => {
                if (selectedCustomer) {
                  orderUpdate.fetch({
                    company: selectedCustomer.id,
                  });
                }
                handleOpen?.(false);
              }}
            >
              Save
            </Button>,
          ]
          : []
      }
      onCancel={() => {
        handleOpen?.(false);
      }}
    >
      <Row gutter={24}>
        <Col span={12}>
          <div style={{ minHeight: LIST_MAX_HEIGHT, overflowY: 'auto' }} className={styles.company}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ margin: '0' }}>Shipper</h2>
              <div>
                <AddNewCustomerModalButton handleOpenAddCustomer={handleOpen} text="Add customer" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {clientOrderData?.shipper.company ? (
                <h4 style={{ margin: '12px 0 0' }}>{clientOrderData?.shipper.company}</h4>
              ) : null}
              {clientOrderData?.shipper.contactName ? (
                <span>{clientOrderData?.shipper.contactName}</span>
              ) : null}
              {clientOrderData?.shipper.phone ? (
                <span>{clientOrderData?.shipper.phone}</span>
              ) : null}
              {clientOrderData?.shipper.email ? (
                <span>{clientOrderData?.shipper.email}</span>
              ) : null}
              {clientOrderData?.shipper.address1 ? (
                <span>{clientOrderData?.shipper.address1}</span>
              ) : null}
              {clientOrderData?.shipper.address2 ? (
                <span>{clientOrderData?.shipper.address2}</span>
              ) : null}
              {clientOrderData?.shipper.postalCode ? (
                <span>{clientOrderData?.shipper.postalCode}</span>
              ) : null}
              {clientOrderData?.shipper.city ? (
                <span>{clientOrderData?.shipper.city}</span>
              ) : null}
              {clientOrderData?.shipper.country ? (
                <span>{clientOrderData?.shipper.country}</span>
              ) : null}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div ref={contentRef} className={styles.company} style={{ height: LIST_MAX_HEIGHT, overflowY: 'auto' }}>
            <h2 style={{ margin: '0 0 10px' }}>Customers</h2>
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginBottom: '12px' }}
            />
            {filteredCustomerData
              ? filteredCustomerData.map((item: Company) => (
                <div key={item.id}>
                  <div style={{ display: 'flex', alignItems: 'start' }}>
                    <Radio
                      checked={selectedCustomer?.id === item.id}
                      onChange={() => handleSelectCustomer(item)}
                    >
                      {selectedCustomer?.id !== item.id
                        ? (
                          <div style={{ marginLeft: '20px' }}>
                            <h4 style={{ margin: '0' }}>{item?.companyName}</h4>
                            <span>{`${item?.address1}, `}</span>
                            <span>{`${item?.city}, `}</span>
                            <span>{item?.country}</span>
                          </div>
                        )
                        : (
                          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: '0' }}>{item?.companyName}</h4>
                            <span>{`${item?.phone}, `}</span>
                            <span>{`${item?.email}, `}</span>
                            <span>{`${item?.address1}, `}</span>
                            <span>{`${item?.city}, `}</span>
                            <span>{item?.country}</span>
                          </div>
                        )}
                    </Radio>
                  </div>
                  <Divider style={{ margin: '10px 0' }} />
                </div>
              ))
              : null}
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default ChooseCustomerModal;

export function ChooseCustomerModalButton({
  variant = 'button',
  text = 'Add customer',
  modalProps,
  handleClick,
  handleCloseModal,
  isModalOpen,
}: {
  text?: string;
  variant?: 'button' | 'link';
  isModalOpen: boolean,
  modalProps?: ChooseCustomerProps,
  handleClick: () => void,
  handleCloseModal: (isOpen: boolean) => void,
}) {
  return (
    <>
      <Button onClick={handleClick} {...(variant === 'link' ? { type: 'link', size: 'small' } : {})}>
        {text}
      </Button>
      <ChooseCustomerModal
        handleOpen={handleCloseModal}
        isModalOpen={isModalOpen}
        {...modalProps}
      />
    </>
  );
}
